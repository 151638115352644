import React, { Component } from 'react'
import config from '../../config'

export default class logout extends Component {
    componentDidMount () {
        localStorage.clear();
        sessionStorage.clear();
        window.location = `${config.home_url}`
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
